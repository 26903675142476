.NL {
  color: #bbdcfe;

  &:hover {
    background-color: #345c7c;
  }

  &.active {
    background-color: #bbdcfe;
    color: #12344d;
  }
}
